export const ACTIONS = {
    ADD_EXPENSE: "Increment",
    ADD_EXPENSE_10: "Increment10",
    REDUCE_EXPENSE: "Decrement",
    REDUCE_EXPENSE_10: "Decrement10",
    DELETE_EXPENSE: "Delete",
    UPDATE_EXPENSE: "UpdateExpense"

}

